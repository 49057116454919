import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		//justify-content: center;
		gap: 18px;
		margin-bottom: 18px;
	}
`;
const RootDivider = styled.div `
	& {
		height: 40px;
		width: 0;
		border-left: 1px solid ${(p) => p.theme.divider};
	}
`;
const RootLogo = styled.img `
	& {
		height: 24px;
		max-width: 100px;
	}
`;
export const GroupLogos = (p) => {
    const logoPharmacy = getRankedLogo(p.pharmacyDetails);
    const logoInstitution = getRankedLogo(p.institutionDetails);
    const isLogo = Boolean(logoPharmacy) || Boolean(logoInstitution);
    if (!isLogo)
        return null;
    return (_jsxs(Root, { children: [_jsx(Icons.MednowLogoWithName, { width: 155, height: 28 }, void 0), _jsx(RootDivider, {}, void 0), logoPharmacy && _jsx(RootLogo, { src: logoPharmacy.url }, void 0), logoInstitution && _jsx(RootLogo, { src: logoInstitution.url }, void 0)] }, void 0));
};
const getRankedLogo = (d) => {
    var _a;
    return Boolean(d === null || d === void 0 ? void 0 : d.rank) ? (_a = d === null || d === void 0 ? void 0 : d.images) === null || _a === void 0 ? void 0 : _a.logoAppActivate : undefined;
};
