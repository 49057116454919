import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Spacer } from "@/src/common/components/layout/Spacer";
import { BreakpointPx } from "@/src/enums/breakpoints";
import { FooterNav } from "@/src/features/common/FooterNav";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { DynamicActionBar } from "@/src/features/Home/DynamicActionBar";
import { HomeFooter } from "@/src/features/Home/HomeFooter";
import { HomeHeaderGreeting } from "@/src/features/Home/HomeHeaderGreeting";
import { HomeSecondaryCTAs } from "@/src/features/Home/HomeSecondaryCTAs";
import { Nav } from "@/src/features/Nav/Nav";
import { NavDrawer } from "@/src/features/Nav/NavDrawer/NavDrawer";
import { useAuth } from "@/src/hooks/useAuth";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { JunctionBanners } from "@/src/modules/junction/components/banner/JunctionBanners";
import { JunctionHomeCards } from "@/src/modules/junction/components/home/JunctionHomeCards";
import { JunctionNextSteps } from "@/src/modules/junction/components/home/JunctionNextSteps";
import { JunctionPopup } from "@/src/modules/junction/components/popup/JunctionPopup";
import { JunctionScreen } from "@/src/modules/junction/type/JunctionScreen";
import { OnboardingSlideshow } from "@/src/modules/onboarding/components/OnboardingSlideshow";
import { WorkflowStatusCategory } from "@/src/modules/workflow/type/WorkflowStatusCategory";
import { WorkflowTypeCategory } from "@/src/modules/workflow/type/WorkflowTypeCategory";
import { useUserRxCountRefillQuery, useWorkflowListCustomerQuery } from "@/src/redux/apiServices/suiteApi";
import qs from "query-string";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
const queryString = qs.stringify({
    statusCategory: [WorkflowStatusCategory.Triage, WorkflowStatusCategory.Progress],
    typeCategory: [WorkflowTypeCategory.Rx, WorkflowTypeCategory.Transfer],
});
const SIZE_OF_SOMETHING_PADDING = 16 * 2;
const PageContainerStyled = styled(PageContainer) `
	& .HomepageContentContainer {
		min-height: 100%;
	}
`;
const Root = styled.div `
	display: flex;
	flex-direction: column;
	min-height: calc(100% - ${SIZE_OF_SOMETHING_PADDING}px);
	width: 100%;
	gap: 16px;

	flex-grow: 1;
	margin: 0 auto;

	// NOTE: when a DAB exists on the page
	// it will have margin-top: -8px to make it
	// such that the top margin is 24px;
	padding-top: 24px;

	// Desktop only styles
	@media (min-width: ${BreakpointPx.Desktop}) {
		padding-top: 32px;
	}
`;
const RootAction = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 4px;
	margin-right: 4px;
`;
export const HomePage = () => {
    var _a, _b, _c, _d;
    const bc = useContext(BrandContext);
    const [isOpen, setIsOpen] = useState(false);
    const { user, isKrollConnection, isUserInRelationshipWithDependents } = useAuth();
    const apiWorkflows = useWorkflowListCustomerQuery({
        queryString: queryString,
        limit: 1,
    }, { skip: !(user === null || user === void 0 ? void 0 : user.guid) });
    const apiRxCountRefill = useUserRxCountRefillQuery({
        userForGuid: (user === null || user === void 0 ? void 0 : user.guid) || "",
        isAddDependents: true,
    }, {
        skip: !isKrollConnection && !isUserInRelationshipWithDependents,
    });
    const rxWorkflows = (_b = (_a = apiWorkflows.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.workflows;
    const rxCountRefill = ((_d = (_c = apiRxCountRefill.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.count) || 0;
    useEffect(() => {
        apiWorkflows.refetch();
    }, [user]);
    return (_jsxs(PageContainerStyled, Object.assign({ background: bc.Colors.homeBackground }, { children: [_jsx(Nav
            //
            , { 
                //
                isStoreBanner: true, dataLeft: { onClick: () => setIsOpen(true), leftItemType: "Menu" }, dataRight: { rightItemType: "PhoneStartChat" } }, void 0), _jsxs(PageOverflowContainer, Object.assign({ contentClassName: "HomepageContentContainer", isFullHeight: true }, { children: [_jsx(PageWrapper, Object.assign({ isDisableVerticalPadding: true, maxWidth: "407px" // 407 = 16 + 375 + 16 (to account for the 16px side margins)
                     }, { children: _jsxs(Root, { children: [_jsx(JunctionBanners, { screen: JunctionScreen.Home }, void 0), _jsx(JunctionPopup, { screen: JunctionScreen.Home }, void 0), rxWorkflows && rxWorkflows.length > 0 && (_jsx(RootAction, { children: _jsx(DynamicActionBar, { rxWorkflows: rxWorkflows }, void 0) }, void 0)), _jsx(HomeHeaderGreeting, {}, void 0), _jsx(JunctionNextSteps, {}, void 0), _jsx(JunctionHomeCards, {}, void 0), _jsx(HomeSecondaryCTAs, { userRxRefillCount: rxCountRefill, isFetchingUserRxCountRefillQuery: apiRxCountRefill.isFetching }, void 0), _jsx(HomeFooter, {}, void 0)] }, void 0) }), void 0), _jsx(Spacer, {}, void 0), _jsx(NavDrawer, { isOpen: isOpen, onClose: () => setIsOpen(false) }, void 0)] }), void 0), _jsx(FooterNav, {}, void 0), _jsx(OnboardingSlideshow, {}, void 0)] }), void 0));
};
