import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth } from "@/src/hooks/useAuth";
import { SlideshowFooter } from "@/src/modules/onboarding/components/slideshow/SlideshowFooter";
import { SlideshowHeader } from "@/src/modules/onboarding/components/slideshow/SlideshowHeader";
import { SlideshowStepList } from "@/src/modules/onboarding/components/slideshow/SlideshowStepInfo";
import { useUserEditMutation } from "@/src/modules/user/redux/ApiCoreUser";
import { UserStatus } from "@/src/modules/user/type/UserStatus";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { Box, Modal } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import styled from "styled-components";
const getModalStyle = () => {
    return {
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
        width: "327px",
        height: "532px",
    };
};
const useStyles = makeStyles((theme) => createStyles({
    paper: {
        position: "absolute",
        width: 327,
        backgroundColor: theme.palette.background.paper,
        border: "2px",
        boxShadow: theme.shadows[1],
    },
}));
const Root = styled(Box) `
	&& {
		box-shadow: none;
		border-radius: 7px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		outline: none;
		padding: 24px;
	}
`;
const RootContent = styled.div `
	& {
		flex: 1;
	}
`;
export const OnboardingSlideshow = (p) => {
    const auth = useAuth();
    const segment = useSegmentAnalytics();
    const user = auth.user;
    const [apiUserEdit, apiUserEditState] = useUserEditMutation();
    const [isOpen, setIsOpen] = useState(false);
    // TODO: reidenzon - DEBUG only!
    // const [step, setStep] = useState<number>(4);
    const [step, setStep] = useState(0);
    const stepMax = SlideshowStepList.length - 1;
    const stepInfo = SlideshowStepList[step];
    const isBack = step != 0;
    const isClose = step == stepMax;
    const isNext = step != stepMax;
    const isSkip = step != stepMax;
    const stepSegment = `step ${step + 1}`;
    const Component = stepInfo.Component;
    const handleBack = () => {
        setStep(step - 1);
        segment.segmentTrackEvent("select_back", {
            entrypoint: SegmentEntryPoint.OnboardingSlideshow,
            step: stepSegment,
        });
    };
    const handleNext = () => {
        setStep(step + 1);
        segment.segmentTrackEvent("select_next", {
            entrypoint: SegmentEntryPoint.OnboardingSlideshow,
            step: stepSegment,
        });
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        if ((user === null || user === void 0 ? void 0 : user.status) == UserStatus.Activated) {
            if (!isOpen) {
                // TODO: reidenzon - Use [junction] popups instead.
                // TODO: reidenzon - DEBUG only!
                // const isOnboardingComplete = false;
                // const isOnboardingComplete = user.metaFlags.isOnboardingComplete;
                // if (!isOnboardingComplete) {
                // 	setIsOpen(true);
                // 	apiUserEdit({
                // 		guid: user.guid,
                // 		metaFlags: {
                // 			isOnboardingComplete: true,
                // 		},
                // 	} as UserEditRequest);
                // }
            }
        }
    }, [user === null || user === void 0 ? void 0 : user.status]);
    return (_jsx(Modal, Object.assign({ open: Boolean(isOpen && auth.isLoggedIn), onClose: handleClose }, { children: _jsxs(Root, Object.assign({ className: useStyles().paper, style: getModalStyle() }, { children: [_jsx(SlideshowHeader
                //
                , { 
                    //
                    isBack: isBack, step: step, steps: SlideshowStepList.length, onBack: handleBack, onStep: (s) => setStep(s) }, void 0), _jsx(RootContent, { children: _jsx(Component, { onClose: handleClose }, void 0) }, void 0), _jsx(SlideshowFooter
                //
                , { 
                    //
                    isClose: isClose, isNext: isNext, isSkip: isSkip, onClose: handleClose, onNext: handleNext, onSkip: handleClose }, void 0)] }), void 0) }), void 0));
};
