import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { DotStepper } from "@/src/common/components/dot-stepper/DotStepper";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Elevations } from "@/src/enums/elevations";
import { useJunctionLinkInfo } from "@/src/modules/junction/type/JunctionLink";
import styled from "styled-components";
const Root = styled.div `
	& {
		background: ${(p) => p.theme.cardBackground};
		border: 1px solid ${(p) => p.theme.divider};
		border-radius: 4px;
		//box-shadow: ${Elevations.FeatureCard};
		display: flex;
		flex-direction: column;
		gap: 12px;
		padding: 24px;
	}
`;
const RootSponsored = styled.div `
	& {
		${TypographyV3.Hint};
	}
`;
const RootTitle = styled.div `
	${TypographyV3.Header3};
`;
const RootDescription = styled.div `
	${TypographyV3.Body};
	white-space: pre-wrap;
`;
const RootButtons = styled.div `
	${TypographyV3.Button};
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 32px;
	margin-top: 12px;
`;
const RootFooter = styled.div `
	& {
		align-items: center;
		border-top: 1px solid ${(p) => p.theme.divider};
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 12px;
		margin-bottom: -12px;
	}
`;
export const JunctionHomeCardsItem = (p) => {
    const item = p.item;
    const linkInfo = useJunctionLinkInfo(item.link);
    const isDismissible = item.dismissible;
    const isSponsored = item.sponsored;
    const isButtons = Boolean(item.link) || isDismissible;
    const isFooter = p.steps > 1;
    return (_jsxs(_Fragment, { children: [_jsxs(Root, { children: [isSponsored && _jsx(RootSponsored, { children: "Sponsored" }, void 0), _jsx(RootTitle, { children: item.title }, void 0), _jsx(RootDescription, { children: item.description }, void 0), isButtons && (_jsxs(RootButtons, { children: [item.link && (_jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.SolidPrimary, content: item.link.title, iconRight: linkInfo.icon, onClick: linkInfo.onClick }, void 0)), isDismissible && (_jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.TextSubtle, content: "Dismiss", isLoading: p.isDismissing, onClick: () => p.onDismiss(item) }, void 0))] }, void 0)), isFooter && (_jsxs(RootFooter, { children: [_jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.TextSubtle, 
                                // content={"Prev"}
                                iconLeft: "chevron_left", onClick: () => p.onStep(p.step - 1) }, void 0), _jsx(DotStepper, { step: p.step, steps: p.steps, onStep: (s) => p.onStep(s) }, void 0), _jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.TextSubtle, 
                                // content={"Next"}
                                iconRight: "chevron_right", onClick: () => p.onStep(p.step + 1) }, void 0)] }, void 0))] }, void 0), linkInfo.outlet] }, void 0));
};
