import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppStoreLink } from "@/src/common/components/app-store/AppStoreLink";
import { Icons } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 12px;
		height: inherit;
	}
`;
export const AppStoreLinks = (p) => {
    return (_jsxs(Root, { children: [_jsx(AppStoreLink, { href: "https://apps.apple.com/ca/app/mednow/id1577116787", icon: _jsx(Icons.DownloadApple, {}, void 0), store: "apple" }, void 0), _jsx(AppStoreLink, { href: "https://play.google.com/store/apps/details?id=ca.mednow.customer", icon: _jsx(Icons.DownloadGoogle, {}, void 0), store: "google" }, void 0)] }, void 0));
};
