import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppStoreLink } from "@/src/common/components/app-store/AppStoreLink";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import { useAuth } from "@/src/hooks/useAuth";
import { useDeviceSelectors } from "react-device-detect";
import styled from "styled-components";
const Root = styled.div `
	& {
		background: ${(p) => p.theme.badgeBackground};
		padding: 12px;
	}
`;
const RootWidth = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 12px;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 360px;
		width: 100%;
	}
`;
const RootContent = styled.div `
	& {
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.Body};
		font-weight: bold;
	}
`;
const RootDescription = styled.div `
	& {
		${TypographyV3.Body};
	}
`;
const RootButton = styled.div `
	& {
		min-width: 120px;
		width: 120px;
	}
`;
export const AppStoreBanner = (p) => {
    const auth = useAuth();
    const [s, d] = useDeviceSelectors(window.navigator.userAgent);
    const isDisplay = useIsDisplay();
    if (!isDisplay)
        return null;
    if (!p.isForce && auth.isLoggedIn)
        return null;
    // TODO: reidenzon - Can we detect if app is installed?! Hmm... probably NO.
    return (_jsx(Root, { children: _jsxs(RootWidth, { children: [_jsxs(RootContent, { children: [_jsx(RootTitle, { children: "Mednow" }, void 0), _jsx(RootDescription, { children: "Get the Mednow app" }, void 0)] }, void 0), _jsxs(RootButton, { children: [s.isIOS && _jsx(AppStoreLink, { href: "https://apps.apple.com/ca/app/mednow/id1577116787", icon: _jsx(Icons.DownloadApple, {}, void 0), store: "apple" }, void 0), s.isAndroid && (_jsx(AppStoreLink, { href: "https://play.google.com/store/apps/details?id=ca.mednow.customer", icon: _jsx(Icons.DownloadGoogle, {}, void 0), store: "google" }, void 0))] }, void 0)] }, void 0) }, void 0));
};
const useIsDisplay = () => {
    // https://github.com/duskload/react-device-detect/blob/HEAD/docs/api.md
    const [s, d] = useDeviceSelectors(window.navigator.userAgent);
    if (!s.isMobile)
        return false;
    if (s.isSafari)
        return false;
    if (!s.isIOS && !s.isAndroid)
        return false;
    return true;
};
