import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridRow } from "@/src/common/components/grid/GridRow";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: start;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const Benefits = (p) => {
    var _a;
    const bc = useContext(BrandContext);
    const itemList = (_a = p.items) !== null && _a !== void 0 ? _a : [
        //
        "Instant access to a pharmacist",
        "Easy prescription refills",
        "Fast, free delivery",
    ];
    return (_jsx(Root, { children: itemList.map((item) => {
            return (_jsxs(GridRow, { children: [_jsx(Symbol, { icon: "check", color: bc.Colors.icon }, void 0), _jsx("span", { children: item }, void 0)] }, item));
        }) }, void 0));
};
