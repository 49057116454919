import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppStoreBanner } from "@/src/common/components/app-store/AppStoreBanner";
import { AppStoreLinks } from "@/src/common/components/app-store/AppStoreLinks";
import { DividerLabel } from "@/src/common/components/layout/DividerLabel";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageFullOverflowContainer } from "@/src/features/common/Page/PageFullOverflowContainer";
import { CssBackgroundImagesValues } from "@/src/features/cssBackgroundImages/cssBackgroundImageValues";
import { LoginForm } from "@/src/features/Login/LoginForm";
import { SSOSignInWithButtons } from "@/src/features/Login/SSOSignInWithButtons";
import { CenterItemContainer, NavContainer, NavContent } from "@/src/features/Nav/Nav";
import { VersionAndConditionalBuildId } from "@/src/features/VersionNumber/VersionAndConditionalBuildId";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { LoginAlternatives } from "@/src/modules/user/components/LoginAlernatives";
import { SegmentEntryPoint } from "@/src/utils/SegmentAnalytics";
import styled from "styled-components";
/* --------------------------------- Styles --------------------------------- */
const LoginPageNavContainer = styled(NavContainer) `
	& ${CenterItemContainer} {
		margin: 0 auto;
	}
`;
const MednowHeaderContainer = styled.div `
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	margin-top: 20px;
`;
const LoginPageContainer = styled.div `
	display: flex;
	flex-direction: column;
	min-height: 100%;
	align-items: center;
	margin: 0 auto;
	width: 322px;
`;
const RootAlternatives = styled.div `
	& {
		margin-top: 32px;
	}
`;
const RootStore = styled.div `
	& {
		height: 42px;
		margin: 42px 0;
	}
`;
const VersionAndConditionalBuildIdStyled = styled(VersionAndConditionalBuildId) `
	${TypographyV3.Hint};

	align-items: center;
	display: flex;
	//position: fixed;
	top: 0;
	height: 56px;
	right: 15px;
	z-index: 9999;

	&,
	& * {
		color: ${(p) => p.theme.invert};
	}
`;
export const LoginPage = () => {
    return (_jsxs(_Fragment, { children: [_jsx(AppStoreBanner, {}, void 0), _jsxs(PageContainer, { children: [_jsx(LoginPageNavContainer, { children: _jsx(NavContent, { children: _jsx(CenterItemContainer, { children: _jsx(Icons.MednowLogoWithWhiteName, { width: 132, height: 24 }, void 0) }, void 0) }, void 0) }, void 0), _jsx(PageFullOverflowContainer, Object.assign({ "$backgroundImages": CssBackgroundImagesValues.Page.LoginAndSignup }, { children: _jsxs(LoginPageContainer, Object.assign({ className: "LoginPageContainer" }, { children: [_jsx(MednowHeaderContainer, { children: _jsx(LayoutContentHeader, { title: "Welcome back" }, void 0) }, void 0), _jsx(LoginForm, {}, void 0), _jsx(DividerLabel, {}, void 0), _jsx(SSOSignInWithButtons, { isLogin: true }, void 0), _jsx(RootAlternatives, { children: _jsx(LoginAlternatives
                                    //
                                    , { 
                                        //
                                        entrypoint: SegmentEntryPoint.Login, isRegister: true, isPhone: true, isFax: true }, void 0) }, void 0), _jsx(RootStore, { children: _jsx(AppStoreLinks, {}, void 0) }, void 0)] }), void 0) }), void 0)] }, void 0)] }, void 0));
};
