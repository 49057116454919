import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppStoreBanner } from "@/src/common/components/app-store/AppStoreBanner";
import { ZIndex } from "@/src/enums";
import { Elevations } from "@/src/enums/elevations";
import { NavCenterItem } from "@/src/features/Nav/NavItems/NavCenterItem";
import { NavLeftItem } from "@/src/features/Nav/NavItems/NavLeftItem";
import { NavRightItem } from "@/src/features/Nav/NavItems/NavRightItem";
import styled from "styled-components";
export const NavContainer = styled.nav `
	position: relative;
	top: 0;
	display: flex;
	box-shadow: ${({ $isStepper }) => ($isStepper ? "" : Elevations.BorderBottom)};
	box-sizing: border-box;
	flex-direction: column;
	flex-shrink: 0;
	flex-grow: 0;
	width: 100%;
	background-color: ${(p) => p.theme.menuBackground};
	z-index: ${ZIndex.Nav};
`;
export const NavContent = styled.div `
	align-items: center;
	display: flex;
	max-width: 100%;
	width: 375px;
	flex-direction: row;
	height: var(--nav-height);
	justify-content: space-between;
	margin: 0 auto;
`;
export const CenterItemContainer = styled.div `
	display: flex;
	align-items: center;
	margin: 12px;

	& svg {
		display: block;
	}
`;
const NavLeftSideItems = styled.div `
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	align-items: center;
`;
const NavCenterItems = styled.div `
	display: flex;
	align-items: center;
`;
const NavRightSideItems = styled.div `
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
`;
export const Nav = (p) => {
    return (_jsxs(_Fragment, { children: [_jsx(AppStoreBanner, { isForce: p.isStoreBanner }, void 0), _jsx(NavContainer, Object.assign({ "$isStepper": p.isStepper }, { children: _jsxs(NavContent, { children: [_jsx(NavLeftSideItems, { children: _jsx(NavLeftItem, Object.assign({}, p.dataLeft), void 0) }, void 0), _jsx(NavCenterItems, { children: _jsx(NavCenterItem, Object.assign({}, p.dataMiddle), void 0) }, void 0), _jsx(NavRightSideItems, { children: _jsx(NavRightItem, Object.assign({}, p.dataRight), void 0) }, void 0)] }, void 0) }), void 0)] }, void 0));
};
