import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ModalFooterRowCenter } from "@/src/common/components/modal/ModalFooterRowCenter";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { ModalContext } from "@/src/common/components/modal/ModalProvider";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin: 24px;
	}
`;
export const WorkflowModalRxOriginal = (p) => {
    return (_jsxs(Root, { children: [_jsx(ModalHeader
            //
            , { 
                //
                title: "Attention!", description: "Bring the original prescription with you when you come to pick up your medication." }, void 0), _jsxs(ModalFooterRowCenter, { children: [_jsx(ButtonStandard, { content: "Back", onClick: p.onBack, style: ButtonStyle.SolidGhost }, void 0), _jsx(ButtonStandard, { content: "Ok, understood!", onClick: p.onNext, style: ButtonStyle.SolidPrimary }, void 0)] }, void 0)] }, void 0));
};
export const useWorkflowModalRxOriginal = () => {
    const mc = useContext(ModalContext);
    return {
        doModal: (p) => {
            mc.AddModal({
                guid: generateGuid(),
                content: (m) => {
                    return (_jsx(WorkflowModalRxOriginal, { onBack: () => {
                            var _a;
                            (_a = p.onBack) === null || _a === void 0 ? void 0 : _a.call(p);
                            m.onClose();
                        }, onNext: () => {
                            p.onNext();
                            m.onClose();
                        } }, void 0));
                },
            });
        },
    };
};
