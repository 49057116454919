import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { useUStatusInfo } from "@/src/common/utility/status/UStatus";
import { useJunctionLinkInfo } from "@/src/modules/junction/type/JunctionLink";
import styled from "styled-components";
const Root = styled.div `
	& {
		//align-items: start;
		background: ${(p) => p.$statusInfo.Color};
		//background: ${(p) => p.theme.cardBackground};
		border: 1px solid ${(p) => p.$statusInfo.Color};
		border-radius: 4px;
		color: ${(p) => p.theme.invert};
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 8px;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.Header6};
		//font-weight: bold;
		color: inherit;
	}
`;
const RootDescription = styled.div `
	& {
		${TypographyV3.Body};
		color: inherit;
	}
`;
const RootFooter = styled.div `
	& {
		${TypographyV3.Body};
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 24px;
		justify-content: space-between;
	}
`;
export const JunctionBanner = (p) => {
    const item = p.item;
    const statusInfo = useUStatusInfo(item.type);
    const linkInfo = useJunctionLinkInfo(item.link);
    const isDismissible = Boolean(item.dismissible);
    const handleDismiss = () => {
        p.onDismiss(item);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Root, Object.assign({ "$statusInfo": statusInfo }, { children: [_jsx(RootTitle, { children: item.title }, void 0), _jsx(RootDescription, { children: item.description }, void 0), _jsxs(RootFooter, { children: [item.link && (_jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.TextBanner, content: item.link.title, onClick: linkInfo.onClick }, void 0)), isDismissible && (_jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.TextBanner, isLoading: p.isDismissing, content: "Dismiss", onClick: handleDismiss }, void 0))] }, void 0)] }), void 0), linkInfo.outlet] }, void 0));
};
